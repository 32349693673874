
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

interface tambah {
  name: string;
  position: string;
  bankCentralId: any;
  bankAreaId: any;
  bankBranchId: any;
  schoolId: any;
  note: string;
  email: any;
  phone: string;
  credentialId: {
    role: string;
  };
  address: {
    province: string;
    city: string;
    regency: string;
    detail: string;
  };
}

interface items {
  combo_bank: any;
  combo_area: any;
  combo_branch: any;
  combo_school: any;
  role: any;
  provinsiOptions: any;
  kabupatenOptions: any;
  kecamatanOptions: any;
  disableButton: boolean;
}

interface cek {
  cekArea: string;
  cekBranch: string;
}

export default defineComponent({
  name: "User",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    let items = reactive<items>({
      combo_bank: [],
      combo_area: [],
      combo_branch: [],
      combo_school: [],
      role: [],
      provinsiOptions: [],
      kabupatenOptions: [],
      kecamatanOptions: [],
      disableButton: false,
    });

    const tambahValidator = Yup.object().shape({
      role: Yup.string().required("Role Wajib Diisi"),

      bankCentralId: Yup.string()
        .when("role", {
          is: "BANK_CENTRAL",
          then: Yup.string().required("Bank Wajib Diisi."),
        })
        .when("role", {
          is: "BANK_AREA",
          then: Yup.string().required("Bank Wajib Diisi."),
        })
        .when("role", {
          is: "BANK_BRANCH",
          then: Yup.string().required("Bank Wajib Diisi."),
        })
        ,

      bankAreaId: Yup.string()
        .when("role", {
          is: "BANK_AREA",
          then: Yup.string().required("Area Wajib Diisi."),
        })
        .when("role", {
          is: "BANK_BRANCH",
          then: Yup.string().required("Area Wajib Diisi."),
        })
        ,

      bankBranchId: Yup.string().when("role", {
        is: "BANK_BRANCH",
        then: Yup.string().required("Cabang Wajib Diisi."),
      }),

      schoolId: Yup.string().when("role", {
        is: "ADMIN_SCHOOL",
        then: Yup.string().required("Sekolah Wajib Diisi."),
      }),

      name: Yup.string().required("Nama Wajib Diisi"),
      phone: Yup.string().required("Nomor Telepon Wajib Diisi"),
      email: Yup.string()
        .required("Email Wajib Diisi")
        .email("Email Harus Valid"),
      province: Yup.string().required("Provinsi Wajib Diisi"),
      city: Yup.string().required("Kabupaten/ Kota Wajib Diisi"),
      regency: Yup.string().required("Kecamatan Wajib Diisi"),
    });

    const tambah = reactive<tambah>({
      name: "",
      position: "",
      bankCentralId: null,
      bankAreaId: null,
      bankBranchId: null,
      schoolId: null,
      note: "",
      email: "",
      phone: "",
      credentialId: {
        role: "",
      },
      address: {
        province: "",
        city: "",
        regency: "",
        detail: "",
      },
    });

    const cek = reactive<cek>({
      cekArea: "",
      cekBranch: "",
    });

    const saveChanges1 = () => {
      isLoading.value = true;

      items.disableButton = true;
      submitButton.value?.setAttribute("data-kt-indicator", "on");
        console.log(tambah);
        isLoading.value = false;
        ApiService.postWithData("crmv2/main_user/user", tambah)
          .then((res) => {
            router.push({ path: "/superadmin/user" });
            toast.success("Berhasil Tambah User");
            submitButton.value?.removeAttribute("data-kt-indicator");

            isLoading.value = false;
          })
          .catch((e) => {
            if (e.response.status == 401) {
              toast.error(e.response.data.detail);
              router.push('/sign-in');
              isLoading.value = false;
            } else {
              items.disableButton = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              toast.error(e.response.data.detail);
              isLoading.value = false;
            }
          });

    };

    const getRole = () => {
      isLoading.value = true;
      ApiService.getWithoutSlug("crmv2/main_user/user/combo_role").then(
        (response) => {
          items.role = response.data;
          isLoading.value = false;
        })
        .catch((error) => {
          toast.error(error.response.data.detail);
          isLoading.value = false;
        });
    };

    const getProvinsi = () => {
      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getAllProvinces"
      ).then((response) => {
        items.provinsiOptions = response.data;
      });
    };

    const getKabupaten = () => {
      var indexProv = items.provinsiOptions.findIndex(
        (x) => x.name === tambah.address.province
      );

      if (indexProv != null) {
        var provId = items.provinsiOptions[indexProv].id;
        ApiService.getWithoutSlug(
          "https://api.katalis.info/wilayah/getRegencies/" + provId
        ).then((response) => {
          items.kabupatenOptions = response.data;
        });
      }
    };

    const getKecamatan = () => {
      var indexKab = items.kabupatenOptions.findIndex(
        (x) => x.name === tambah.address.city
      );
      if (indexKab != null) {
        var kabupatenId = items.kabupatenOptions[indexKab].id;
        ApiService.getWithoutSlug(
          "https://api.katalis.info/wilayah/getDistricts/" + kabupatenId
        ).then((response) => {
          items.kecamatanOptions = response.data;
        });
      }
    };

    const getComboBank = () => {
      ApiService.getWithoutSlug("crmv2/main_bank/bank/office/combo_banks").then(
        (response) => {
          items.combo_bank = response.data;
        })
        .catch((error) => {
          toast.error(error.response.data.detail);
        });
    };


    const getComboArea = () => {
      ApiService.getWithoutSlug(
        "crmv2/main_bank/bank/office/" + tambah.bankCentralId + "/combo_areas"
      )
        .then((response) => {
          cek.cekArea = "1";
          items.combo_area = response.data;
        })
        .catch((error) => {
          cek.cekArea = "0";
          toast.error(error.response.data.detail);
        });
    };

    const getComboBranch = () => {
      ApiService.getWithoutSlug(
        "crmv2/main_bank/bank/office/" +
          tambah.bankCentralId +
          "/" +
          tambah.bankAreaId +
          "/" +
          "combo_branchs"
      )
        .then((response) => {
          cek.cekBranch = "1";
          items.combo_branch = response.data;
        })
        .catch((error) => {
          cek.cekBranch = "0";
          toast.error(error.response.data.detail);
        });
    };

    const getSchool = () => {
      ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
        (response) => {
          items.combo_school = response.data;
        })
        .catch((error) => {
          toast.error(error.response.data.detail);
        });
    };

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_USER);
      setCurrentPageBreadcrumbs("User", ["Data"]);
      getProvinsi();
      getRole();
      getComboBank();
      getSchool();
    });

    return {
      submitButton,
      saveChanges1,
      tambah,
      tambahValidator,
      items,
      getKabupaten,
      getKecamatan,
      getRole,
      getComboBank,
      getComboArea,
      getComboBranch,
      getSchool,
      cek,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
});
